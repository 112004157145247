<template>
<v-app>
  <div>
    <v-alert dark dismissible v-model="alert" border="left" class="alert" :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
        {{alertMessage}}
    </v-alert>
  </div>
  <div class="row">
    <div class="col-md-6 ml-auto mr-auto">
      <div class="mb-8 mt-8 text-center">
          <h1>Download Timesheet</h1>
      </div>
      <v-form ref="salarycomponent" v-model="valid">
        <v-row no-gutters>
          <v-col>
            <SelectField :items="salaryCycles" v-model="formData.salaryCycle" :label="'Salary Cycle'" :itemText="'description'" :itemValue="'id'" :returnObject="true" @input="selectedCycle" />
          </v-col>
           <v-col>
            <SelectField :items="rotations" v-model="formData.rotation" :label="'Rotation'" :itemText="'name'" :itemValue="'id'" :returnObject="true" @input="selectedRotation" />
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>

    <!-- timesheet calendar  -->

  <div v-if="timeSheets.length > 0 " class="scroll row">
    <div class="col-md-10 col-sm-12 ml-auto mr-auto">
      <v-simple-table>
          <template v-slot:default>
              <thead>
                  <tr>
                      <th>Personnel</th>
                      <th v-for="item in headers" :key="item.headerName">{{item.headerName}}</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="timesheet in timeSheets" :key="timesheet.employeeId">
                      <td>
                          {{timesheet.name}}
                      </td>
                      <td v-for="(item, index) in timesheet.timesheetItems" :key="item.timesheetItemDate">
                          <span v-if="timesheet.timesheetItems[index].show === true">
                            <!-- <b>Work Hrs</b> -->
                            <span>{{timesheet.timesheetItems[index].hoursWorked}}</span>
                          </span>
                          <span v-if="timesheet.timesheetItems[index].show === false">
                            <b>{{timesheet.timesheetItems[index].type}}</b>
                          </span>
                      </td>
                  </tr>
              </tbody>
          </template>
      </v-simple-table>
    </div>
  </div>
    <!--end timesheet calendar  -->
  <v-row v-if="showDownload">
    <v-col class="col-md-11 col-sm-12 mt-5 d-flex justify-end">
        <Button :label="'Download'" :btnType="'Submit'" @onClick="onDownload" :isLoading="isTimesheet" class="" />
    </v-col>
  </v-row>
</v-app>
</template>

<script>
// import TextField from '@/components/ui/form/TextField.vue'
import SelectField from '@/components/ui/form/SelectField.vue'
import Button from '@/components/ui/form/Button.vue'
// import { UPLOAD_TIMESHEET, SAVE_TIMESHEET } from '@/store/action-type'
import { salaryPeriodService, rotationService, timesheetService } from '@/services'
import moment from 'moment'

export default {
  components: {
    // TextField,
    Button,
    SelectField
  },
  props: ['value'],
  data () {
    return {
      showDownload: false,
      rotations: [],
      selectedPersonnel: [],
      alertMessage: '',
      alert: false,
      alertType: '',
      valid: false,
      isTimesheet: false,
      formData: {
        requestId: '',
        personnel: '',
        salaryCycle: {},
        rotation: {}
      },
      search: '',
      personnelSearch: [],
      requestType: [],
      salaryCycles: [],
      personnel: [],
      selected: [],
      headers: [],
      timeSheets: [],
      serverTimeSheets: [],
      searching: false,
      creating: false
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    onDownload () {
      this.isTimesheet = true
      timesheetService.downloadTimesheet(this.timeSheets).then(result => {
        const now = moment().format()
        const fileName = `Timesheet_${now}.xlsx`
        const url = window.URL.createObjectURL(new Blob([result.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        this.isTimesheet = false
      }).catch(error => {
        console.log(error)
        this.isTimesheet = false
      })
    },
    selectedCycle (salaryCycle) {
      const { rotation } = this.formData
      if (rotation !== undefined && salaryCycle !== undefined) {
        timesheetService.filterTimesheet(salaryCycle.id, rotation.id).then(result => {
          this.serverTimeSheets = result.data.items
          this.createTimesheetTable(salaryCycle)
        }).catch(error => {
          console.log(error)
        })
      }
    },
    selectedRotation (rotation) {
      this.headers = []
      this.timeSheets = []
      const { salaryCycle } = this.formData
      if (rotation !== undefined && salaryCycle !== undefined) {
        timesheetService.filterTimesheet(salaryCycle.id, rotation.id).then(result => {
          this.serverTimeSheets = result.data.items
          this.createTimesheetTable(salaryCycle)
        }).catch(error => {
          console.log(error)
        })
      }
    },
    createTimesheetTable (salaryData) {
      this.showDownload = false
      let dateArr = []
      const prevDate = moment(salaryData.startDate).format('MM-DD-YYYY')
      const nextDate = moment(salaryData.endDate).format('MM-DD-YYYY')

      var start = new Date(prevDate)
      var end = new Date(nextDate)

      while (start < end) {
        dateArr.push({
          headerName: moment(start).format('ddd, MMM D'),
          date: moment(start).format('MM-DD-YYYY')
        })
        var newDate = start.setDate(start.getDate() + 1)
        start = new Date(newDate)
      }

      this.headers = dateArr
      if (this.serverTimeSheets.length > 0) {
        const newTimesheets = []
        this.serverTimeSheets.forEach(timesheet => {
          const newTimesheetItems = []
          this.headers.forEach(header => {
            const date = moment(header.date).format('MM-DD-YYYY')
            let timeSheetItem = timesheet.timesheetItems.find(item => moment(item.timesheetItemDate).format('MM-DD-YYYY') === date)
            if (timeSheetItem !== undefined) {
              if (timeSheetItem.type === 'Holiday' || timeSheetItem.type === 'On Leave') {
                timeSheetItem = Object.assign({}, timeSheetItem, { hoursWorked: 0, show: true })
              } else {
                timeSheetItem = Object.assign({}, timeSheetItem, { show: true })
              }
              newTimesheetItems.push(timeSheetItem)
            } else {
              newTimesheetItems.push({
                timesheetId: timesheet.timesheetId,
                timesheetItemDate: date,
                hoursWorked: 0,
                isRegular: false,
                isOvertime: false,
                isShift: false,
                type: 'Off day',
                show: false
              })
            }
          })

          const names = `${timesheet.employee.firstName}, ${timesheet.employee.lastName}`
          const newTimesheet = {
            timesheetId: timesheet.id,
            name: names,
            prnumber: timesheet.employee.prnumber,
            employeeId: timesheet.employeeId,
            salaryPeriodId: this.formData.salaryCycle.id,
            timesheetItems: newTimesheetItems,
            rotationHours: 0
          }

          this.showDownload = true
          newTimesheets.push(newTimesheet)
        })

        this.timeSheets = newTimesheets
      }
    },
    onReset () {
      this.$refs.salarycomponent.reset()
    }
  },
  mounted () {
    salaryPeriodService.getOpenSalaryPeriod().then(result => {
      this.salaryCycles = result.data
    }).catch(error => {
      console.log(error)
    })

    rotationService.getAllRotation().then(result => {
      this.rotations = result.data.items
    })
  }
}
</script>

<style scoped>
  .radiogroup {
      width: 50%;
      align-items: flex-start !important;
  }

  .row .col {
      box-shadow: none !important;
      padding: 0 !important;
  }

  .alert {
      position: fixed;
      z-index: 9999;
      top: 5px;
      min-width: 50%;
  }

  .alert-success {
      color: #155724;
      background-color: #d4edda;
      border-color: #c3e6cb;
  }

  .alert-error {
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
  }

  .btn-style {
      color: rgba(0, 0, 0, 0.6);
      text-align: left;
  }

  .text-style {
      text-transform: capitalize;
  }

  .actionBtn {
      display: flex;
      justify-content: flex-end;
  }

  .hour-input {
      border: 1px rgb(156, 154, 154) solid;
      width: 50px;
      padding: 1px;
  }

  .v-data-table__wrapper {
      overflow-y: auto !important;
      max-height: 400px !important;
  }
</style>
